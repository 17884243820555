<template>
	<div id="Lucky_open">
		<div class="Title">
		 <div class="block">
		
		</div>
			<!-- <p>{{DetailData.name}}</p> -->
		</div>
		<Details :DetailData="DetailData" :NumIdx="NumIdx/100" v-show="AnimationShow == '0'"></Details>
		<Dynamic :DetailList="DetailList" :Awardlist="AwardArr" :Dynamic_checked="Dynamic_checked" :Dynamic_num="Dynamic_num" @setAnimation="setAnimation" @setEquipment="setEquipment" v-show="AnimationShow == '1'"></Dynamic>
		<!-- <div style="color:#fff;text-align: center;">
			{{DetailData.dura_alias}}
		</div> -->
		<div class="box_proportion">
			<!-- <van-slider v-model="NumIdx" @change="onChange" :min="5" :max="95"/> -->
			<div class="proportion_item min">
				<div class="shadow"></div>
				{{NumIdx}}%
			</div>

			<div class="proportion_out">
				
				<div class="block">
					<!-- <span class="demonstration">显示间断点</span> -->
					<el-slider
					v-model="NumIdx"
					:step="1"
					:min="5" :max="75"
					show-stops>
					</el-slider>
				</div>
				<!-- <van-slider v-model="NumIdx" @change="onChange" :min="5" :max="75">
				  <template #button>
				    <div class="custom-button">{{ NumIdx }}</div>
				  </template>
				</van-slider> -->
			</div>
			
			<div class="proportion_item max">
				<div class="shadow"></div>
				{{100 - NumIdx}}%
			</div>
		</div>
		
		<div class="open_btn" v-show="AnimationShow == '0'">
			<div class="Animation_switch">
				<p>跳过动画</p>
				<van-switch v-model="Animation_checked" size="15px" />
			</div>
			<van-button type="info" size="small" @click="PostOpen" class="btn_van" style="color:#000 !important">{{(this.bean*this.NumIdx/100).toFixed(2)}} 开启箱子</van-button>
		</div>
		
		<Equipment v-if="false" :Dynamic_num="Dynamic_num" :EquipmentItem="EquipmentItem" v-show="EquipmentShow == '1'" :key="EquipmentShow"></Equipment>
		<Show  v-show="WinningShow" :WinningShow="WinningShow" @setShow="setShow" :Awardlist="Awardlist"></Show>
		
	</div>
</template>

<script>
	import Details from '@/components/PubOpen/luckyDetails.vue'
	import Dynamic from '@/components/PubOpen/Dynamic.vue'
	import Equipment from '@/components/PubOpen/Equipment.vue'
	import Show from '@/components/PubOpen/Show.vue'
	import { AwardGet, LuckyOpen, PersonalInfo } from '@/network/api.js'
	import { mapMutations } from 'vuex'
	export default {
		name: 'Open',
		data() {
			return {
				DetailData:{},//箱子信息
				NumIdx:1,//箱子比列
				DetailList:[],//箱内物品
				Animation_checked: false, //动画开关
				WinningShow:false,//奖品遮罩层
				Awardlist:[],//获得奖品数据
				AwardArr:[],//获得奖品数据(操作后)
				Dynamic_checked:'0',//播放动画('0':不播放,'1':播放)
				Dynamic_num:0,//奖品数量
				AnimationShow:'0',//动画显示隐藏'0':不显示,'1':显示)
				EquipmentItem:{},//下方奖品栏子级
				EquipmentShow:'0',//下方奖品栏显示
				 value: 0,
				 bean:'',
			}
		},
		
		created() {
			this.GetAwardGet()
		},
		
		methods:{
			//宝箱详情
			GetAwardGet(){
				AwardGet(this.$route.query.id).then((res) => {
					// console.log(res.data.data)
					this.bean=res.data.data[0].bean
					this.DetailData = res.data.data[0]
					let Arr = []
					Arr.push(res.data.data[0],{
						cover:require('@/assets/images/q2/luckyin/wen.png')
					})
					this.DetailList = Arr.concat(Arr)
					// console.log(this.DetailList)
				})
			},
			
			//开箱
			PostOpen(){
				LuckyOpen(this.$route.query.id,(this.NumIdx/100)).then((res) => {
					// console.log(this.NumIdx/100)
					// console.log(res.data.data.awardlist)
					this.GetPersonalInfo()
					this.Awardlist = res.data.data.awardlist
					// if(res.data.data.awardlist)
					
					if(res.data.data.awardlist[0].name == this.DetailData.name){
						this.AwardArr = res.data.data.awardlist
					}else{
						this.AwardArr = [{cover:require('@/assets/images/q2/luckyin/wen.png')}]
					}
					
					if(this.Animation_checked == true){
						this.WinningShow = true
					}else{
						this.AnimationShow = '1'
						this.EquipmentShow = '1'
						// console.log('false')
						setTimeout(() => {
							this.Dynamic_checked = '1'
							this.Dynamic_num = this.Awardlist.length
						},100)
					}
				})
			},
			
			//
			onChange(val){
				// console.log(val)
			},
			
			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},
			
			//接收子级(关闭奖品遮罩层)
			setShow(v){
				// console.log(v)
				this.WinningShow = v
				this.EquipmentShow = '0'
			},
			
			//接收子级(动画结束)
			setAnimation(v){
				this.WinningShow = v
				this.AnimationShow = '0'
				this.Dynamic_checked = '0'
			},
			
			//接收子级(下方奖品栏)
			setEquipment(v){
				// console.log(v)
				this.EquipmentItem = this.Awardlist[v - 1]
			},
			
			...mapMutations([
				'PostUser'
			])
		},
		
		components: {
			Details,
			Dynamic,
			Equipment,
			Show
		}
	}
</script>

<style scoped lang="scss">
::v-deep .el-slider__button{
	background: url('https://cdn.vipcsgo.com/client/img/rate_ic.8285e6d.png') no-repeat 50%;
	border: none;
	background-size: 100% 100%;
	width: 46px;
	height: 30px;
}
.block{
	background: url('https://cdn.vipcsgo.com/client/img/progress_bg.b2ea88a.png') no-repeat center;
	background-size: 100% 30%;
}
::v-deep .el-slider__runway{
	background: none !important;
}
::v-deep .el-slider__stop{
	background: none !important;
}
::v-deep .el-slider__bar{
	// background: #000;
	// width: 1px;
	background: url('https://cdn.vipcsgo.com/client/img/line_bg.09d38a2.png') !important;
}
	#Lucky_open {
		background: url('../../assets/images/q2/luckyin/luckybac.png') no-repeat center;
		background-size: 100% 100%;
		.van-button--info{
			width: 50% !important;
			padding: 30px;
			background: url('../../assets/images/q2/luckyin/btn.png') !important;
			background-size: 100% 100% !important;
			border: 0 !important;
		}
		.box_proportion{
			width: 100%;
			padding: 0 18px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: -80px;
			border-radius: 6px;
			box-sizing: border-box;
			margin-top: 1px;
			.proportion_item{
				width: 50px;
				height: 50px;
				background: red;
				text-align: center;
				line-height: 50px;
				font-size: 16px;
				position: relative;
				.shadow{
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%);
					transform: translateY(-50%);
				}
				&.min{
					background: url(../../assets/images/Lucky/min.svg) no-repeat center;
					background-size: contain;
					color: rgb(170, 240, 143);
					.shadow{
						box-shadow: rgb(6, 128, 54) 0px 0px 30px 12px;
					}
				}
				&.max{
					background: url(../../assets/images/Lucky/max.svg) no-repeat center;
					background-size: contain;
					color: rgb(240, 179, 143);
					.shadow{
						box-shadow: rgb(240, 179, 143) 0px 0px 30px 12px;
					}
				}
				
			}
			.proportion_out{
				width: 170px;
				.custom-button {
				   width: 34px;
				   font-size: 10px;
				   line-height: 20px;
				   text-align: center;
				   background-color: #fff;
				   border-radius: 100px;
				   color: #1989fa;
				 }
			}
			
			
		}
		.open_btn {
			text-align: center;
			margin-top: 20px;
			
			.van-button {
				font-size: 12px;
				padding-left: 20px;
				padding-right: 20px;
				width: 95%;
				height: 35px;
				color:  black !important;
				font-weight: 900;
			}
		
			.Animation_switch {
				display: flex;
				align-items: center;
				justify-content: center;
				padding-right: 10px;
				// margin-top: 10px;
				box-sizing: border-box;
				font-size: 16px;
				color: #fff;
				margin-bottom: 30px;
		
				p {
					margin-right: 5px;
				}
			}
		}
	}
</style>
