<template>
	<div id="Show">
		<van-overlay :show="true">
			<div class="wrapper">
				<div class="Show_box">
					<img class="Top_img" src="@/assets/images/open/Show_top.png" alt="">
					<div class="Show_list">
						<div class="Show_item"  v-for="(item,index) in Awardlist" :key="index" @click="SelectItem(item,index)" ref="Select"  :class="item.lv | LvGuoLv" >
							<div class="Show_pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}" >
								<img :src="item.cover" alt="">
							</div>
							<p>{{item.name}}</p>
							<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
							
						</div>
					</div>
					<div class="Show_btns">
						<van-button type="info" @click="setShow">继续开箱</van-button>
						<van-button type="info" @click="PostCash">分解<span class="Gold"><img src="@/assets/images/public/Gold.png" alt="">{{TotalPrice.toFixed(2)}}</span></van-button>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		Cash, PersonalInfo
	} from '@/network/api.js'
	import {
		mapMutations
	} from 'vuex'
	import { Notify } from 'vant';
	export default {
		name: 'Show',
		props: {
			Awardlist: { //奖品列表
				type: Array
			},
			WinningShow: { //控制声音
				type: Boolean
			}
		},
		data() {
			return {
				boom: new Audio(require('@/assets/audio/boom.aac')), //奖品展示声音
				TotalPrice: 0, //分解总价格
			}
		},
		filters: {
			LvGuoLv(val){
				if (val == '1') {
					return 'lv1'
				} else if (val == '2') {
					return 'lv2'
				} else if (val == '3') {
					return 'lv3'
				} else if (val == '4') {
					return 'lv4'
				}else if (val == '5') {
					return 'lv5'
				}else if (val == '6') {
					return 'lv6'
				}
			},
		},
		methods: {
			//默认全部选中
			Select_All(){
				for (let i = 0; i < this.Awardlist.length; i++) {
					let key = "Select"
					let value = true
					this.Awardlist[i][key] = value
					setTimeout(() => {
						this.$refs.Select[i].style.border = "1px solid #E9B10E"
					},10)
					
				}
				// console.log(this.Awardlist)
				this.TotalPriceCal()
			},
			
			//选中
			SelectItem(item, index) {
				// console.log(item)
				// console.log(index)
				if (item.Select) {
					delete item.Select
					this.$refs.Select[index].style.border = "1px solid transparent";
				} else {
					let key = "Select";
					let value = true
					item[key] = value
					this.$refs.Select[index].style.border = "1px solid #E9B10E"
				}
				this.TotalPriceCal()
			},

			// 计算分解总价
			TotalPriceCal() {
				this.TotalPrice = 0
				let A_Arr = this.Awardlist.filter((v) => {
					return v.Select == true
				})

				for (let k = 0; k < A_Arr.length; k++) {
					this.TotalPrice += Number(A_Arr[k].bean)
				}

			},

			//兑换金币(分解)
			PostCash() {
				let arr = this.Awardlist.filter((item) => {
					return item.Select == true
				})
				let data = []
				for (let i = 0; i < arr.length; i++) {
					data.push({
						"id": arr[i].id,
						"uuid": arr[i].uuid
					})
				}
				Cash(JSON.stringify(data)).then((res) => {
					// console.log(res)
					this.RefreshData(res.data.message)
					this.setShow()
				})
			},

			//刷新数据
			RefreshData(message) {
				// this.$notify({
				// 	type: 'success',
				// 	message: message
				// })
				Notify({
					message: message,
					color: '#ad0000',
					background: '#fff',
				});
				this.GetPersonalInfo()
			},

			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},

			
			//子传父(关闭奖品遮罩层)
			setShow() {
				this.$emit('setShow', false)
			},
			
			...mapMutations([
				'PostUser'
			])
		},
		
		watch: {
			//播放声音并且调用Select_All方法
			WinningShow(v) {		
				if (v == true) this.boom.play() && this.Select_All()				
			}
		}
	}
</script>

<style lang="scss">
	#Show {
		.Show_box {
			width: 90%;
			padding: 10px;
			box-sizing: border-box;
			border-radius: 10px;
			position: relative;
			text-align: center;

			.Top_img {
				width: 60%;
			}

			.Show_list {
				width: 100%;
				// height: 50vh;
				overflow-y: scroll;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				
				.Show_item {
					width: 130px;
					height:120px;
					margin: 0 5px;
					margin-top: 10px;
					padding: 10px;
					// background: url(../../assets/images/public/pub_back.png) no-repeat center;
					// background-size: cover;
					// border-radius: 6px;
					// border: 1px solid #E9B10E;
					box-sizing: border-box;
					&.lv1{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgba(233, 212, 90, 0.358);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
						background: url('../../assets/images/985/open/1.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv2{
						background-size: 100% 100%;
						border-radius: 5px;
						// border: 1px solid rgb(210, 73, 73);
						background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
						background: url('../../assets/images/985/open/4.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv3{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgb(203, 70, 203);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,44,83));
						background: url('../../assets/images/985/open/3.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv4{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgb(86, 86, 214);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,79,102));
						background: url('../../assets/images/985/open/2.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv5{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgb(96, 96, 113);
						background: linear-gradient(to top, rgba(81, 72, 72, 0), rgb(87, 92, 104));
						background: url('../../assets/images/985/open/5.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv6{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgba(233, 212, 90, 0.358);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
						background: url('../../assets/images/985/open/1.png') no-repeat center;
						background-size: 100% 100%;
					}
					.Show_pic {
						width: 80px;
						height: 60px;
						margin: 0 auto;
						background-image: url(http://www.gameskins.cn/img/LV4.9815642e.png);
						background-position: center;
						background-size: 100% 100%;
						background-repeat: no-repeat;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							max-width: 100%;
							max-height: 100%;
							vertical-align: middle;
						}
					}

					p {
						width: 100%;
						padding: 0 5px;
						box-sizing: border-box;
						font-size: 12px;
						color: #fff;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					span {
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						color: #f1bc60;
						margin: 5px 0 10px;

						img {
							width: 18px;
							height: 18px;
							vertical-align: middle;
						}
					}
				}
				@keyframes bgAnimation{
					0%{
					background-position: 0% 50%;
					}
					50%{
					background-position: 100% 50%;
				
					}
					100%{
					background-position: 0% 50%;
				
					}
				}
				.goodsHows{
					background: linear-gradient(125deg,#2c3e50,#27ae60,#2980b9,#f7d600,#e74c3c);
					background-size: 500%;
					animation: bgAnimation 7s linear infinite;
				}

			}

			.Show_btns {
				margin-top: 10px;
				color: black !important;
				.van-button {
					width: 130px;
					height: 40px;
					padding: 0;
					margin-right: 20px;
					color: black !important;
					font-weight: 500;
					&:last-child {
						margin-right: 0;
					}
					.Gold {
						margin-left: 5px;
						color: black;
						font-weight: 300;

						img {
							width: 18px;
							height: 18px;
							vertical-align: middle;
							margin-bottom: 2px;
							margin-right: 2px;
						}
					}
				}
			}
		}
	}
</style>
